.cont-notas-tablero-estu2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 6%;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #04293a;
}
.cont-notas-tablero-estu2 h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #04293a;
  padding: 10px 0px 0px 30px;
}

.p-fecha-tablero-estu1 {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #04293a;
  text-align: right;
  padding: 10px 30px 0px 0px;
}
.p-nota-tablero-estu1 {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #04293a;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-left: 30px;
}
