.btn-acti-biblioteca2{
    border: 1px solid rgba(255, 153, 60, 1);
    box-sizing: border-box;
    border-radius: 7px;
    color:rgba(255, 153, 60, 1) ;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
}
.btn-acti-lectura2{
    background: rgba(255, 153, 60, 1);
    border-radius: 7px;
    color:white;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 13px;
    margin-right: 30px;
    cursor: pointer;
}
.btn-acti-lectura2:hover{
    background: linear-gradient(180deg, rgba(98, 199, 255, 0.5) 0%, rgba(98, 199, 255, 0) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, #FF993C, #FF993C);

}
.btn-iniciar-acti-estu-2{
    display: inline-block;
    width: 180px;
    height: 52px;
    background: #FF993C;
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 15px 40px;
    margin-left: 70px;
    margin-bottom: 10px;
    cursor: pointer;
}
.card-actividades-estu2{
    display: grid;
    grid-template-columns: 1fr .7fr;
    background-image: url('../img/fondo-card.svg');
    background-color:rgba(182, 182, 182, 0.2);
    border-radius: 10px;
    margin-bottom: 60px;
    max-width: 875px;
}
.card-actividades-estu2 h5{
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    color: #3D3C3C;
}
.card-actividades-estu2 h6{
    font-weight: 300;
    font-size: 15px;
    line-height: 20px; 
    color: #3D3C3C;
}
.card-actividades-estu2 p{
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    padding-right: 50px;
    color: #3D3C3C;
}
.card-actividades-estu2 img{
   margin: 40px 60px;
}
.cont-datos-evaluacion-estu2{
    background: rgba(216, 216, 216, 1);
    margin: 40px 0px 10px 50px;
    border-radius: 10px;
}
.cont-titu-card-actividades2{
    padding: 20px 20px 10px 40px;
}
.progres-bar-actividadesa2{
    width: 230.85px;
    height: 5.88px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-left: 16%;
    margin-bottom: 20px;
}
