* {
  margin: 0px;
}
.areas-interes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.areas-interes > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid red;
  color: red;
  font-size: 13px;
  font-weight: 300;
  height: 30px;
  padding-top: 14px;
}
.actividades-grados {
  width: 73%;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.btn-menu {
  padding: 10px 5px 10px 16px;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: 0.2fr 1fr;
  max-height: 60px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.btn-menu svg {
  width: 35px;
  height: 35px;
  color: grey;
  padding: 3px;
  margin-left: 4px;
  margin-right: 7px;
}
.btn-menu img {
  width: 35px;
  height: 35px;
  color: grey;
  padding: 3px;
  margin-left: 4px;
  margin-right: 7px;
}
.btn-menu svg path {
  width: 30px;
  height: 30px;
  color: grey;
  padding: 3px;
  margin-left: 8px;
}
.buscador-docentes {
  height: 40px;
  width: 52%;
  margin-top: 50px;
  margin-left: 180px;
  border-radius: 30px;
  outline: none;
  border: 0px solid rgba(114, 114, 114, 0.411);
  padding-left: 15px;
  position: relative;
  z-index: 3000;
}
.contenedor {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: aquamarine;
}
.cont-areasInteres {
  display: flex;
  flex-direction: column;
  box-shadow: -2px -2px 4px rgba(163, 163, 163, 0.226),
    2px 2px 4px rgba(163, 163, 163, 0.226),
    -2px 2px 4px rgba(163, 163, 163, 0.226),
    2px -2px 4px rgba(163, 163, 163, 0.226);
  width: 66%;
  margin-top: 60px;
  padding: 10px 30px;
  border-radius: 6px;
}
.cont-areasInteres div h5 {
  font-weight: 900;
}
.cont-botones-info {
  background-color: #d1efeb;
  color: #00639a;
  font-weight: 800;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.cont-datos-docentes {
  background-color: #0092e3;
  height: 260px;
  width: 87%;
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
  margin-top: 35px;
}
.cont-descripcion {
  display: inline-block;
  margin-left: 30px;
  height: 170px;
  width: 60%;
  min-width: 51vw;
  background-color: #e8eaf6;
  border-radius: 20px;
  padding: 35px 30px 20px 30px;
  box-shadow: 2px 2px 4px rgba(87, 87, 87, 0.247);
}
.cont-descripcion h5 {
  font-weight: 900;
  font-size: 25px;
}
.cont-descripcion p {
  font-size: 18px;
  color: rgb(88, 88, 88);
  font-weight: 500;
}
.cont-editar {
  width: 50px;
  height: 50px;
  padding: 5px 5px 0 10px;
  margin: 10px 0 10px 76%;
  border-radius: 100%;
  box-shadow: -2px -2px 2px rgba(182, 182, 182, 0.22),
    2px 2px 2px rgba(182, 182, 182, 0.22),
    -2px 2px 2px rgba(182, 182, 182, 0.22),
    2px -2px 2px rgba(182, 182, 182, 0.22);
}
.cont-editar img {
  width: 30px;
  height: 30px;
}
.cont-encotrar-info {
  border-radius: 25px;
  box-shadow: 2px 2px 3px rgba(128, 128, 128, 0.315);
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.35fr;
  height: 240px;
}
.cont-contacto-docentes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 60px 140px;
  grid-gap: 20px;
}
.cont-header {
  display: grid;
  border-bottom: 1px solid rgba(139, 139, 139, 0.418);
  box-shadow: 2px 0 5px rgba(139, 139, 139, 0.397);
  grid-template-columns: 1.7fr 0.28fr 0.5fr;
  justify-content: center;
  align-items: center;
  max-height: 70px;
  background-color: white;
  position: relative;
  z-index: 7000;
}
.con-ico-areaInteres > img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-top: 9px;
  margin-bottom: 10px;
}
.cont-ico-editar {
  width: 35px;
  margin-left: 90%;
  height: 35px;
  position: relative;
  top: -30px;
  cursor: pointer;
}
.cont-ico-editar img {
  width: 35px;
  width: 35px;
}
.cont-ico-editar svg path {
  width: 10px;
  width: 10px;
}
.cont-ico-header {
  display: inline-block;
  margin-left: 26px;
  cursor: pointer;
}
.cont-ico-header > img {
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: grey;
}
.con-ico-header-pri {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-idiomas {
  box-shadow: -2px -2px 2px rgba(182, 182, 182, 0.22),
    2px 2px 2px rgba(182, 182, 182, 0.22),
    -2px 2px 2px rgba(182, 182, 182, 0.22),
    2px -2px 2px rgba(182, 182, 182, 0.22);

  border-radius: 5px;
  padding: 15px 30px;
  padding-bottom: 20px;
  margin-right: 15px;
}
.cont-infoDocente {
  display: grid;
  grid-template-columns: 0.6fr 0.9fr 0.3fr;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgba(128, 128, 128, 0.267);
  margin-bottom: 8px;
}

.contenedor-info {
  display: inline-block;
  width: 100%;
}
.cont-info-colegio {
  display: flex;

  margin-left: 250px;
  justify-content: center;
  color: white;
  margin-top: 20px;
}
.cont-info-colegio > div {
  margin: 0 90px;
}

.contenedor-info-pri-coordinator {
  display: inline-block;
  grid-column-start: 2;
  grid-column-end: 3;
}
.cont-info-subDatos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cont-intereses {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
}
.cont-imagen-docentes {
  width: 100%;
  height: 400px;
  /* background: url('../img/docentes.png'); */
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: cover;
}
.cont-imagen-docentes::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #0013683f;
  z-index: 1000;
  top: 69px;
  left: 80px;
  width: 94%;
  height: 400px;
}
.cont-imagen-docentes1 {
  width: 100%;
  height: 335px;
  /* background: url('../img/docentes.png'); */
  background-repeat: no-repeat;
  background-position: center 0px;
  background-size: cover;
}
.cont-imagen-docentes1::after {
  content: "";
  position: absolute;
  background: linear-gradient(
    95deg,
    rgba(0, 99, 154, 1) 0%,
    rgba(0, 99, 145, 0.6362744927072391) 44%,
    rgba(0, 99, 154, 0.18249298010219717) 80%,
    rgba(255, 175, 141, 0) 100%
  );
  z-index: 1000;
  top: 69px;
  left: 80px;
  width: 94%;
  height: 335px;
}
.cont-imagen-info {
  position: absolute;
  left: 170px;
  top: 205px;
}
.cont-imagen-info1 {
  width: 20px;
  height: 10px;
  cursor: pointer;
}

.cont-princi-docentes {
  margin: 0;
}
.contenedor-pri-coordinator {
  display: grid;
  grid-template-columns: auto 95vw;
}
.cont-nombre-profesion {
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: -60px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  text-align: left;
}
.cont-nombre-profesion > h4 {
  font-weight: 900;
  font-size: 32px;
  margin: 0;
}
.cont-nombre-profesion > h5 {
  font-weight: 200;
  margin-left: -30px;
  font-size: 32px;
}
.cont-nombreDocente {
  padding-top: 12px;
  font-weight: 700;
}
.cont-sobreMi {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.cont-titulo-idiomas {
  text-align: center;
}
.cont-titulo-idiomas h6 {
  font-size: 20px;
  font-weight: 900;
}
.datos-idioma {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  margin: 0;
}
.datos-idioma > p {
  padding-top: 0px;
  margin: 0;
}
.datos-idioma > div {
  padding: 0px 4px;
}
.editar-info-formacion {
  width: 30px;
  height: 35px;
}
.filtro-docentes {
  margin-left: 70px;
  outline: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  position: relative;
  z-index: 3000;
  width: 15%;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 5px;
}
.formacion-academica {
  display: grid;
  grid-template-columns: 0.09fr 1fr 0.03fr;
}

.formacion-academica h4 {
  font-weight: 900;
  padding-top: 10px;
  font-size: 21px;
  color: rgb(65, 65, 65);
}
.foto-perfilDoce {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.grados {
  display: grid;
  grid-template-columns: 1fr 0.04fr;
  grid-template-rows: 1fr auto;
  padding: 15px 40px;
  font-size: 20px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.233);
  font-weight: 900;
}
.info-docentes {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
}
.info-docentes-nombre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1px 30px;
  font-size: 12px;
}
.info-docentes-nombre p {
  padding: 0;
}
.info-docentes-nombre h6 {
  font-weight: 900;
}

.image-info-forAcademica img {
  width: 50px;
  height: 50px;
}
.imagen-info {
  width: 235px;
  height: 230px;
  border-radius: 100%;
}
.imagen-info1 {
  width: 40px;
  height: 40px;
}
.imagen-infoDocente {
  margin-left: 6px;
  height: 115px;
  width: 85px;
  border-radius: 50px;
}
.linea1 {
  height: 2px;
  width: 40%;
  margin: 7px 0px;
}
.linea2 {
  height: 1px;
  width: 24%;
}
.linea-color1 {
  background-color: #b3d1e1;
}
.linea-color2 {
  background-color: orange;
}
.lista-profesiones {
  padding: 5px 70px 20px 70px;
  justify-content: center;
}
.lista-profesiones li strong {
  color: #00639a;
  font-weight: 900;
}
.lista-profesiones li {
  color: rgb(82, 82, 82);
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 23px;
}
.logo {
  width: 110px;
  height: 80px;
}
.profeciones {
  margin-top: 40px;
  width: 73%;
  box-shadow: -1px -1px 1px rgba(163, 163, 163, 0.116),
    2px 2px 4px rgba(163, 163, 163, 0.226),
    -2px 2px 4px rgba(163, 163, 163, 0.226),
    2px -2px 4px rgba(163, 163, 163, 0.226);
  border-radius: 17px;
  padding: 25px 46px;
}
.profecion-docente {
  margin-top: 20px;
  padding-left: 30px;
  font-weight: 900;
}
.profecion-docente1 {
  color: #00639a;
}
.profecion-docente2 {
  color: orange;
}
.titulo-actividades-grados {
  background-color: #c7cfff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 15px 40px;
  font-weight: 900;
  font-size: 20px;
}

.titulo-Vista {
  color: white;
  font-size: 35px;
  padding-left: 180px;
  padding-top: 130px;
  font-weight: bold;
  position: relative;
  z-index: 3000;
}
.view-title-coordinator {
  display: flex;
  align-items: center;
  color: white;
  font-size: 35px;
  padding-left: 180px;
  font-weight: bold;
  position: relative;
  z-index: 3000;
}

.view-title-coordinator > svg {
  margin: 0px 15px;
  cursor: pointer;
}
.mensaje-docentes {
  border-right: 2px solid #00639a;
}
.menu-coordinador {
  height: 100%;
  box-shadow: 0px 2px 4px rgba(139, 139, 139, 0.616);
  z-index: 6000;
}

.menu-coordinador div li a {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 13px;
}
.menu-coordinador div li p {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 13px;
}
.menu-coordinador:hover {
  position: relative;
  transition: width 0.4s;
  width: 270px;
  height: 100%;
  background-color: white;
}

.menu-coordinador:not(:hover) {
  transition: width 0.4s;
  width: 80px;
  background-color: white;
  height: 100%;
}
.menu-des-header-coordinador {
  position: absolute;
  background-color: white;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  width: 20%;
  margin-top: 7.5%;
  padding: 5px;
}
.menu-des-header-coordinador a {
  text-decoration: none;
  color: black;
}
.menu-des-header-coordinador > div {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
}
.menu-des-header-coordinador > div:hover {
  background-color: rgba(179, 179, 179, 0.568);
}
.menu-des-header-coordinador > div > a:hover {
  text-decoration: none;
  color: black;
}
.menu-des-header-coordinador > div > img {
  width: 15px;
  height: 15px;
  margin-right: 30px;
}
.mis-cursos {
  margin-left: -510px;
  margin-top: 50px;
}
.mis-cursos h4 {
  font-weight: 900;
}
.mis-cursos div {
  font-weight: 900;
  font-size: 22px;
  color: #00639a;
}
.mis-cursos div p {
  display: inline-block;
  cursor: pointer;
}

.separador {
  display: inline-block;
  height: 13px;
  margin: 2px 7px;
  border-left: 1px solid rgba(82, 82, 82, 0.603);
  padding: px 0px;
  position: relative;
  top: 4px;
}
.separador1 {
  display: inline-block;
  height: 10px;
  margin: 2px 25px;
  border-left: 1px solid rgba(82, 82, 82, 0.603);
  padding: px 0px;
  position: relative;
  top: 0px;
}
.sub-menu-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-menu-header img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.url {
  color: white;
  padding-top: 30px;
  padding-left: 35px;
  cursor: pointer;
  position: relative;
  z-index: 3000;
}
.url1 {
  color: white;
  padding-top: 15px;
  padding-left: 35px;
  cursor: pointer;
  position: relative;
  z-index: 3000;
  font-size: 14px;
}

.backdrop-home {
  display: grid;
  place-items: center;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 4000;
  text-align: center;
  color: #fff;
  font-size: 30px;
}

.container-event {
  position: relative;
}

.backdrop-event {
  width: 50%;
  height: 100%;
}

.backdrop-circulars {
  width: 100%;
  height: 100%;
}

.backdrop-calendar {
  width: 50%;
  height: 50%;
}

#cont-ubi-colegio {
  margin: 0 7px;
}
