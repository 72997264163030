.cont-aulas-estu3{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    margin: 190px 16% 30px 18%;
}

.cont-materias-estu3{
    border-radius: 30px 90px;
    background: rgba(0, 99, 154, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 130px;
    margin-right: 50px;
}
.cont-materias-estu3 img{
    width: 228px;
    height: 228px;
    position: relative;
    top:-60px
}
.cont-materias-estu3 > div{
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #04293A;
    position: relative;
    top:-30px;
}