.a-sub-menu-estu1{
    color: white;
}
.a-sub-menu-estu1:hover{
    color: white;
    text-decoration: none;
}
.a-header-estudiantes1{
    text-decoration: none;
    color: rgba(4, 41, 58, 1);
}
.a-header-estudiantes1:hover{
    text-decoration: none;
    color: white;
}

.cont-iconos-estudiantes1{
    margin-left: 36%;
    display: grid;
    grid-template-columns:.5fr .5fr .5fr .6fr .7fr .7fr  ;
    align-items: flex-end;
    justify-content: flex-end;
}
.cont-info-estudiantes1{
    grid-column-start: 2 ;
    grid-column-end: 3;
    
}

.cont-header-estudiantes{
    background-color: rgba(255, 153, 60, 1);
    display: grid ;
    grid-template-columns: .4fr 1fr  ;
    grid-template-rows:1fr 1fr ;
    height: 200px;
    justify-content: center;
    align-items: center;
}
.cont-menu-estudiantes1{
    display: grid;
    grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
   
}
.cont-sub-menu-header-estudiante{
    background-color: rgba(255, 153, 60, 1);
    display: grid;
    grid-template-columns: 1fr;
    width: 200px;
    position: absolute;
    left: 86vw;
    top:80px;
    color:white;
    cursor: pointer;
}
.cont-sub-menu-header-estudiante > div:hover{
    background-color: rgb(189, 114, 45);
    
}
.cont-sub-menu-header-estudiante > div{
    padding: 10px;
}
.cont-vista{
    display: grid;
    grid-template-columns: .3fr 1fr;
}

.logo-header{
    margin: 10px 20px; 
    grid-row-start: 1;
    grid-row-end: 3;
}
.logo-header img{
    height: 78px;
    width: 125px;
    
}
.iconos-img-estudiantes{
    color:#04293A;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    width: 50px;
    height: 50px;
    padding: 5px 7px;
}



.iconos-img-estudiantes:hover{
    background: rgba(96, 57, 22, 0.2);
    cursor: pointer;
    border-radius: 100%;
 
}
.iconos3-img-estudiantes{
    color:#04293A;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    width: 90px;
    height: 50px;
    padding: 5px 7px;
}

.iconos-img-estudiantes-avatar img{
    width: 65px;
    height: 65px;
}


.ico-menu-home-estudiantes1 svg{
    width: 30px;
    height: 30px;
    margin-right: 10px;

}
.ico-menu-home-estudiantes1 a{
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: #04293A;

}
.ico-menu-home-estudiantes1{
    border: 3px solid  rgba(4, 41, 58, 1);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin-right: 30px;
}
.icono-sub-menu-heder-estu1{
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;
}
.ico-menu-home-estudiantes1:hover{
    transition: background-color 0.2s ;
    background-color:rgba(4, 41, 58, 1) ;
    fill:white;
    cursor: pointer;
    position: relative;
    top:-5px
}
.ico-menu-home-estudiantes1:hover svg > g{
    
    fill:white;
}
.ico-menu-home-estudiantes1:hover a{
    
    color:white;
}
.img-color{
    fill: white !important;
}
.isvg-iconos-header-estu3 > g{
    fill: white;
}
.nombre-header-estu1{
    color: white; 
}
.nombre-header-estu1 p{
    color: white;
    position: relative;
    z-index: 10;
    margin: 0;
    
}
.nombre-header-estu1:hover > svg{
    transition: opacity .4s;
    opacity: 1 !important;
    
}
.sub-menu-header{
    width: 45px;
    height: 45px;
    padding-top: 5px;
    cursor: pointer;
    height: 60px;

}
#cohete-header-estu1{
    position: absolute;
    top:0;
    z-index: 5;
    left:1070px;
    opacity: 0;
}
