
.btn-acti-biblioteca{
    border: 1px solid #0092E3;
    box-sizing: border-box;
    border-radius: 7px;
    color:#0092E3 ;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
}
.btn-acti-biblioteca:hover{
    border: 1px solid #FF993C;
    box-sizing: border-box;
    border-radius: 7px;
    color:#FF993C ;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
}
.btn-acti-lectura{
    background: #0092E3;
    border-radius: 7px;
    color:white;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 13px;
    margin-right: 30px;
    cursor: pointer;
}
.btn-acti-lectura:hover{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(0deg, #FF993C, #FF993C);
}
.btn-iniciar-acti-estu-1{
    display: inline-block;
    width: 180px;
    height: 52px;
    background: #0092E3;
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 15px 40px;
    margin-left: 170px;
    cursor: pointer;
}
.btn-iniciar-acti-estu-1:hover{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, #FF993C, #FF993C);
    
}
.btn-libros-acti-estu1{
    display: flex;
    justify-content: flex-end;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-right: 100px;
}
.card-actividades-estu1{
    display: grid;
    grid-template-columns: 1fr .7fr;
    background-color: rgba(0, 99, 154, 0.2);
    border-radius: 10px;
    margin-bottom: 60px;
    max-width: 875px;
}
.card-actividades-estu1:hover{
    transition: box-shadow .3s;
    box-shadow: 4px 4px 4px rgba(255, 153, 60, 0.25);
}
.card-actividades-estu1 h5{
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    color: #3D3C3C;
}
.card-actividades-estu1 h6{
    font-weight: 300;
    font-size: 15px;
    line-height: 20px; 
    color: #3D3C3C;
}
.card-actividades-estu1 p{
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    margin-left: 125px;
    text-align: left;
    color: #3D3C3C;
}
.card-actividades-estu1 img{
    margin-top: 30px;
    margin-left: 30px;
    width: 70%;
    height: 70%;
    border-radius: 10%;
}
.card-cont-img-actividades{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

}
.circulo-Actividades-estu1{
    position: absolute;
    width: 130px;
    height: 130px;
    background: rgba(161, 161, 161, 0.8);
    border-radius: 100%;
    top:390px;
    left: 70px;
    filter: blur(30px);
    transform-origin: 210px 0px ; 
    animation: rotarEvaluaciones 6s 1;
}
.circulo1-Actividades-estu1{
    position: absolute;
    width: 130px;
    height: 130px;
    background-color: rgba(0, 164, 255, 0.8);
    border-radius: 100%;
    top:240px;
    left: 310px;
    filter: blur(30px);
    transform-origin: -80px 110px ; 
    animation: rotar2Evaluaciones 6s 1;
}
.cont-acti-estu-1{
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px 22%;
}
.cont-actividades-estu1{
    display: grid;
    grid-template-columns: 1fr .6fr
}
.cont-calendario-acti-estu1{
    margin: 50px 40px 0px 20px;
}
.cont-titu-card-actividades{
    padding: 50px 20px 10px 130px;
}
.cont-libro-actividad-estu1{
    display: grid;
    grid-template-columns: .5fr 1fr;
    margin: 10px 2% 70px 18%;
}
.cont-libro-actividad-estu1 h5{
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-top: 60px;
    color: #04293A;
}
.cont-libro-actividad-estu1 p{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}
.iconos-libro-acti-estu{
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
}
.iconos-libro-acti-estu img{
    margin: -20px 10px;
}
.ico-selecio-libro-estu1{
    background-color: rgba(196, 196, 196, 0.44);
    padding: 7px;
    border-radius: 5px;
}
.info-libro-actividades-estu1{
    display: grid;
    grid-template-columns: 1fr .2fr;
    grid-template-rows: .2fr 1fr;
}

.info-libro-actividades-estu1 > h5{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start: 1;
    grid-row-end: 2;
}
.info-libro-actividades-estu1 > p{
    grid-column-start: 1;
    grid-column-end:2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-right: 40px;
}
.info-libro-actividades-estu1 > img{
    grid-column-start: 2;
    grid-column-end:3;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 34px;
    height: 34px;
    margin-top: 100px;
    
}
.progres-bar-actividadesa{
    width: 230.85px;
    height: 5.88px;
    background: #FFFFFF;
    border-radius: 20px;
    margin-left: 39%;
    margin-top: 10px;
    margin-bottom: 10px;
}
