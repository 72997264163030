
.agregarActividad-curso{
    font-size: 16px;
    color: #00639A;
    padding: 0px 30px;
    border-radius: 100px;
    font-weight: 700;
    border :2px solid #00639A;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 230px;
    cursor: pointer;
}
.agregarActividad-curso:hover{
    transition: color .7s;
    color: white;
    transition: background-color .7s;
    background-color:#00639A ;
}
.agregarActividad-curso strong{
    padding-right: 10px;
    font-size: 30px;
}
.btn-ver-actividad-crear{
    color: white;
    background-color: #0092E3;
    padding: 15px 0px;
    margin: 0px 10px;
    border-radius: 7px;
    cursor: pointer;
}
.btn-ver-agregar-crear{
    background-color: white;
    border: 1px solid #0092E3;
    color: #0092E3;
    padding: 13px 0px;
    margin: 0px 10px;
    border-radius: 7px;
    cursor: pointer;
}
.card-cont-img img{
    height: 150px;
    width: 100%;
    border-bottom-right-radius: 60px;
    border-top-left-radius: 20px;
    
}
.card-curso-actividades{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr .4fr;
    min-width:450px ;
    min-height: 245px;
    margin: 20px 40px;
    background-color: #E1E4FF;
    border-radius: 20px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.267);
}
.card-info-actiCurso {
    margin: 30px 25px;
}
.card-info-actiCurso > h3{
    color: #3d3c3c8c;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
}
.card-info-actiCurso > h3 > strong{
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    
    color: #2c2c2c;
}
.card-info-actiCurso > p{
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #3D3C3C;
    white-space: 1px;
    margin-top: 25px;
}
.con-btn-actiCurso{
    grid-column-start: 1;
    grid-column-end: 3;
    cursor: pointer;
}
.cont-btn-actividadCurso{
    background-color: #00639A;
    color: white;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 90px;
    border-radius: 12px;
    margin-bottom: 10px;
}
.cont-cards-acti-curso{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0px 180px;
}
.cont-cards-acti-calificar{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 50px;
}
.cont-datos-actividad-crear{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    align-items: center;
    padding: 20px 5px;
    text-align: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    margin: 8px 16%;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;

    color: #3D3C3C;
}
.cont-filto-crear-actividad {
   display: flex;
   align-items: center;
   margin-bottom: 30px;
}
.cont-filto-crear-actividad select{
    border: none;
    border-bottom: 2px solid rgba(0, 146, 227, 1);
    width: 40%;
    margin-left: 70px;
    color:rgba(0, 146, 227, 1);

}
.cont-pri-actividad-docente{
    margin-top: -70px;
}
.cont-input-buscador-crearActi{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.cont-input-buscador-crearActi input{
   width: 100%;
   height: 40px;
   margin-bottom: 130px;
   background: #F5F6F8;
   border-radius: 40px;
   outline: none;
   border: none;
}
.cont-tabla-actividad{
    display: grid;
    grid-template-columns:  repeat(5,1fr);
    border-bottom :3px solid #0092E3; 
}
.cont-tabla-actividad > div{
    text-align: center;
    padding: 5px 25px;
    color: #0092E3;
    font-weight: 900;
    font-size: 18px;
}
.cont-titulo-actividadesCrear{
    margin-top: -80px ;
    margin-left: -30px;
}
.cont-titulo-actividadesCrear p{
   position: absolute;
   color: white;
   top: 130px;
   left: 90px;
    font-size: 11px;
}
.cont-titulo-actividadesCrear h2{
    position: absolute;
    color: white;
    top: 210px;
    left: 120px;
 
 }
.cont-titu-actividade-curso{
    margin-top: -60px;
    margin-bottom: 30px;
}
.cont-titulo-retroceder-activCurso{
    display: flex;
    align-items: flex-end;
    margin-bottom: 140px;
}
.curso-actividades-acti{
    color: #00639A;
    border-bottom: 3px solid #00639A;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding-right: 10px;
    margin-right: 40px;
    cursor: pointer;
}
.curso-actividades-desacti{
    color: #777777;
    font-size: 18px;
    font-weight: 400;
    padding-right: 10px;
    cursor: pointer;
}
.header-crear-actividades{
    display: grid;
    grid-template-columns: .8fr 1fr 1fr;
}
.header-curso-actividades{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.modal-ver-actividad{
    position: absolute;
    background-color: rgba(59, 59, 59, 0.26);
    top:0;
    left: 90px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-ver-actividad >div{
    
    background-color: rgb(255, 255, 255);
}
.titulo-curso-actividades{
    color: #00639A;
    border-bottom: 3px solid #00639A;
    font-size: 30px;
    font-weight: 600;
    margin-left: 50px;
    padding-right: 40px;
}
.volver-actividadCurso{
    color: #00639A;
    border-radius: 100%;
    border: 3px solid  #00639A;
    font-weight: 900;
    font-size: 28px;
    width: 40px;
    height: 40px;
    padding:  0px 5px;
    cursor: pointer;
}
.eliminar{
    color: red;
    cursor: pointer;
}
.calificar{
    color: #8ee259;
    cursor: pointer;
}
.pointer{
    cursor:pointer;
}
