.titu-perfil-estu3{
    background-color: rgba(86, 164, 207, 1);
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    font-weight: 700;
    font-size: 33px;
    line-height: 41px;
    color: #FFFFFF;
    padding: 20px 40px;
    margin-left: -50px;
}