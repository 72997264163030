.container-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  width: 120px;
}

.container-pagination > button {
  border: none;
  background-color: transparent;
}
