/* src/StarRating.css */
.star {
    font-size: 2em;
    color: gold;
}

.star.empty {
    color: lightgray;
}

.star.half {
    color: gold;
    background: linear-gradient(90deg, gold 50%, lightgray 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}