.astronauta-gigante-estu1 img {
  width: 264px;
  height: 389px;
  position: absolute;
}
.barras-cortas-cont-estu-1 {
  margin-top: -30px;
  animation: moverBarracorta 4s infinite;
}
.barras-largas-cont-estu-1 {
  margin-top: -70px;
  animation: moverBarralarga 4s infinite;
}
.btn-salir-home-estu1 {
  background: #00a4ff;
  border-radius: 10px;
  color: white;
  height: 55px;
  width: 210px;
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  margin: 25px 15px;
  padding-top: 10px;
}
.btn-salir-home-estu1:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, #ff993c, #ff993c);
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  transition: left 2s;
  left: 2px;
  transition: top 2s;
  top: -2px;
}
.cont-card-abajo-home {
  grid-row-start: 2;
  grid-row-end: 4;
}
.cont-card-arriba-home {
  grid-row-start: 1;
  grid-row-end: 3;
}
.cont-card-menu-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 0.3fr 1fr;
  margin-left: -260px;
  margin-bottom: 200px;
}
.cont-card-menu-home > a > div {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 164, 255, 0.26) 0%,
    rgba(18, 102, 140, 0.77) 100%
  );
  border-radius: 20px;
  height: 374px;
  width: 290px;
}

.cont-card-menu-home > a:hover {
  text-decoration: none;
}
.cont-card-menu-home h2 {
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
  text-decoration: none;
}
.cont-datos-mejor-colegio {
  width: 80%;
}
.cont-datos-mejor-colegio h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
.con-nobre-home-estu1 h4 {
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
  text-align: center;
  margin-top: 100px;
}
.cont-icono-sub-menu-home-estu1 {
  border: 4px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  padding: 10px 10px;
  text-align: center;
}
.cont-icono-sub-menu-home-estu1:hover {
  border: none;
  box-sizing: border-box;
  transition: background-color 2s;
  background-color: white;
  transition: color 0.7s;
  color: rgba(4, 41, 58, 1);
  cursor: pointer;
}
.cont-icono-sub-menu-home-estu1:hover > svg > g {
  transition: fill 0.7s;
  fill: rgba(4, 41, 58, 1);
}

.cont-info-principla-home {
  background: #00052b;
  height: 170vh;
}
.cont-notas-tablero-estu1 {
  background: #e1e1e1;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 6%;
  height: 80px;
}
.cont-notas-tablero-estu1 h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #04293a;
  padding: 10px 0px 0px 30px;
}
.cont-mesaje-home-estu1 {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  margin: 10px 16%;
}
.cont-mensaje-perfil-estu1 {
  background: #fff8df;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px 30px 0px 30px;
  padding: 30px 40px;

  color: #04293a;
}

.cont-mensaje-perfil-estu1 h4 {
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
}

.cont-mensaje-perfil-estu1 p {
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
}
.cont-mision-estu1 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(4, 41, 58, 0.24) 0%,
    rgba(61, 61, 61, 0) 100%
  );
  height: 548px;
  margin-left: -160px;
  margin-right: 130px;
}
.cont-sub-menu-ico-estu1 {
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr;
  margin: 50px 12% 10px 12%;
}
.cont-svg-notificaciones-estu2 {
  width: 50px;
  height: 50px;
  margin: 35px 15px;
  padding: 5px 7px;
}
.cont-svg-notificaciones-estu2:hover {
  border-radius: 100%;
  background: rgba(64, 64, 64, 0.2);
}
.cont-tablero-home-estu1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(166, 188, 199, 1);
  box-shadow: -24px -14px rgba(4, 41, 58, 0.83);
  border-radius: 70px;
  width: 100%;
  height: 500px;
  margin: 90px 170px 100px -140px;
  padding: 10px 30px;
}

.container-note-list {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
}
.cont-tablero-home-estu1 h4 {
  font-weight: 700;
  font-size: 33px;
  line-height: 41px;
  color: #04293a;
  padding: 20px 10px;
}
.con-titu-table-mejor-estu1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #12668c;
  border-radius: 5px;
  color: white;
  padding: 20px 20px;
  text-align: center;
  margin-bottom: 7px;
}
.cont-tdatos-table-estu1 {
  background: rgba(221, 221, 221, 0.95);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 50px;
  margin: 7px 0px;
}
.con-titu-tablero-estu1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
.con-titu-tablero-estu1 p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #04293a;
  padding: 20px 0px;
}
.datos-mejor-estu1 {
  display: grid;
  grid-template-columns: 1fr;
}
.icono-home-1-menu {
  width: 52px;
  height: 52px;
  margin: 30px 10px 0px 10px;
  padding: 5px 7px;
}
.icono-home-1-menu:hover {
  transition: background-color 0.3s;
  background-color: rgba(255, 202, 24, 0.2);
  border-radius: 100%;
  width: 52px;
  height: 52px;
}
.iconos-svg-home-estu1 {
  display: flex;
  justify-content: flex-end;
}
.iconos-svg-cont1-home-estu1 {
  display: flex;
  justify-content: space-around;
  width: 80px;
  height: 60px;
  margin: 35px 15px;
  padding: 5px 7px;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}
.iconos-svg-home-estu1 svg {
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.isvg-iconos-header-estu4 g {
  fill: white;
}
.p-fecha-tablero-estu1 {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #04293a;
  text-align: right;
  padding: 10px 30px 0px 0px;
}
.p-nota-tablero-estu1 {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #04293a;
  grid-column-start: 1;
  grid-column-end: 3;
  padding-left: 30px;
}
.mejores-colegio-home-estu1 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: #04293a;
  width: 100vw;
  height: 100%;
  margin-left: -500px;
  position: absolute;
  z-index: -1;
}

.mejores-colegio-home-estu1 > div {
  background: #00052b;
  margin-left: 10px;
}

.triangulo {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 100px solid #fff8df;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  left: 57.6%;
  top: 304vh;
  display: none;
}
.titu-ciclo-estu1-home {
  font-weight: 400;
  font-size: 56px;
  line-height: 96px;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
}

.best-of-school {
  width: 100%;
  position: relative;
}

#cohete-estu1 {
  position: absolute;
  top: 130vh;
  left: 40vw;
}
#marte-estu1 {
  position: absolute;
  width: 92px;
  height: 92px;
  top: 130vh;
  left: 88vw;
}
#luna-estu1 {
  position: absolute;
  width: 92px;
  height: 92px;
  top: 150px;
  left: 40vw;
}

@keyframes girarCarta {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}
@keyframes mision1 {
  0% {
    position: relative;
    left: 130px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    color: rgba(255, 255, 255, 0);
  }
  100% {
    width: 100%;
    height: 239px;
    display: inline-block;
  }
}

@keyframes moverBarracorta {
  0% {
    margin-top: -30px;
  }
  40% {
    margin-top: -70px;
  }
  90% {
    margin-top: -29px;
  }
  100% {
    margin-top: -30px;
  }
}

@keyframes moverBarralarga {
  0% {
    margin-top: -70px;
  }
  40% {
    margin-top: -30px;
  }
  90% {
    margin-top: -69px;
  }
  100% {
    margin-top: -70px;
  }
}
