.borde-curso-info {
  height: 60px;
  border-left: 2px solid #000000;
  border-right: 2px solid #000000;
}
.circulo-curso-estu1 {
  position: absolute;
  width: 130px;
  height: 130px;
  background: rgba(255, 111, 111, 0.8);
  border-radius: 100%;
  top: 390px;
  left: 120px;
  filter: blur(30px);
  transform-origin: 210px 0px;
  animation: rotarEvaluaciones 6s 1;
}
.circulo1-curso-estu1 {
  position: absolute;
  width: 130px;
  height: 130px;
  background-color: rgba(0, 164, 255, 0.8);
  border-radius: 100%;
  top: 250px;
  left: 380px;
  filter: blur(30px);
  transform-origin: -80px 110px;
  animation: rotar2Evaluaciones 6s 1;
}
.cont-info-curso-estu1 {
  font-weight: 700;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px 70px;
}
.cont-info-curso-estu1 h5 {
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #04293a;
}
.cont-info-curso-estu1 p {
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: #04293a;
}
.cont-contac-curso-estu {
  border: 2px dashed #04293a;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
  padding: 40px 0px;
  margin-top: -50px;
}
.cont-contac-curso-estu:hover {
  border: 2px dashed white;
  box-sizing: border-box;
  transition: background-color 0.3s;
  background-color: #04293a;
  color: white;
  cursor: pointer;
}
.cont-contac-curso-estu:hover > h5 {
  transition: color 0.3s;
  color: white;
}
.cont-contac-curso-estu:hover > p {
  transition: color 0.3s;
  color: white;
}
.cont-contac-curso-estu:hover svg g {
  transition: fill 0.3s;
  fill: white;
}
.cont-contac-curso-estu h5 {
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  color: #04293a;
}
.cont-contac-curso-estu p {
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: #04293a;
}
.cont-contac-curso-estu svg {
  padding-right: 10px;
}
.cont-contac-curso-estu svg > g {
  fill: #042839;
}
.cont-list-ranking-curso-estu1 {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  padding: 50px 200px 10px 70px;
  gap: 31.25em;
}
.cont-list-ranking-curso-estu1 li {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #04293a;
}
.circulo1-aulas-estu12 {
  position: absolute;
  width: 140px;
  height: 140px;
  background-color: rgba(60, 219, 47, 0.8);
  border-radius: 100%;
  top: 260px;
  left: 410px;
  filter: blur(30px);
  z-index: 1000;
}
.curso-info-estu1 {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  margin-left: 80px;
}
.curso-img1 {
  left: 50px;
}
.curso-img2 {
  top: 30px;
}
.curso-img3 {
  left: -50px;
  top: 90px;
  width: 151px;
  height: 155px;
  z-index: -2;
}
.img-curso-estu1 {
  position: relative;
}
.ranking-estu1-curso {
  background: #a6bcc7;
  border-radius: 70px;
  box-shadow: -25px -15px rgba(4, 41, 58, 0.83);
  margin: 90px 15%;
  padding: 30px;
}
.titu-rankig-curso-estu1 {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  width: 100%;
}
.titu-rankig-curso-estu1 h4 {
  font-weight: 900;
  font-size: 33px;
  line-height: 41px;
  color: #04293a;
}
.titu-rankig-curso-estu1 p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #04293a;
  padding-right: 20px;
}

.ul-nombre-curso-estu1 li {
  list-style-type: decimal;
  margin-bottom: 15px;
}
.ul-puntos-curso-estu1 li {
  display: flex;
  list-style-type: none;
  text-align: right;
  margin-bottom: 15px;
}
.ul-puntos-curso-estu1 img {
  margin-left: 6px;
}
