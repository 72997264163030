.a-sub-menu-estu1{
    color: white;
}
.a-sub-menu-estu1:hover{
    color: white;
    text-decoration: none;
}
.a-header-estudiantes1{
    text-decoration: none;
    color: rgba(4, 41, 58, 1);
}
.a-header-estudiantes1:hover{
    text-decoration: none;
    color: white;
}
.ico-menu-home-estudiantes1:hover{
    transition: background-color 0.2s ;
    background-color:rgba(4, 41, 58, 1) ;
}
.img-color{
    fill: white !important;
}
.cont-iconos-estudiantes1{
    margin-left: 48%;
    display: grid;
    grid-template-columns:.5fr .5fr .5fr .6fr .7fr .7fr  ;
    align-items: flex-end;
    justify-content: flex-end;
}
.cont-info-estudiantes1{
    grid-column-start: 2 ;
    grid-column-end: 3;
    
}

.cont-header-estudiantes{
    background-color: rgba(255, 153, 60, 1);
    display: grid ;
    grid-template-columns: .4fr 1fr  ;
    grid-template-rows:1fr 1fr ;
    height: 200px;
    justify-content: center;
    align-items: center;
    
}
.cont-header-estudiantes3{
    background-color: rgba(10, 39, 73, 1);
    display: grid ;
    grid-template-columns: .4fr 1fr  ;
    grid-template-rows:1fr 1fr ;
    height: 200px;
    justify-content: center;
    align-items: center;
    
}
.cont-menu-estudiantes1{
    display: grid;
    grid-template-columns: .5fr 1fr 1fr 1fr 1fr;
   
}
.cont-sub-menu-header-estudiante3{
    background-color: rgba(10, 39, 73, 1);
    display: grid;
    grid-template-columns: 1fr;
    width: 200px;
    position: absolute;
    left: 86vw;
    top:80px;
    color:white;
    cursor: pointer;
}
.cont-sub-menu-header-estudiante3 > div:hover{
    background-color: rgb(0, 32, 68);
    
}
.cont-sub-menu-header-estudiante3 > div{
    padding: 10px;
}
.cont-vista{
    display: grid;
    grid-template-columns: .3fr 1fr;
}

.logo-header{
    margin: 10px 20px; 
    grid-row-start: 1;
    grid-row-end: 3;
}
.logo-header img{
    height: 78px;
    width: 125px;
    
}
.iconos-img-estudiantes3{
    color:#fff;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
}

.ico-home-estudiantes1 img{
    width: 50px;
    height: 50px;
}

.iconos-img-estudiantes img {
    width: 38px;
    height: 38px;
}
.iconos-img-estudiantes-avatar img{
    width: 65px;
    height: 65px;
}
.ico-menu-home-estudiantes3 svg{
    margin-right: 10px;

}
.ico-menu-home-estudiantes3 a{
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: white;

}
.ico-menu-home-estudiantes3{
    border: 3px solid  white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin-right: 30px;
}
.ico-menu-home-estudiantes3:hover{
    transition: background-color 0.2s ;
    background-color:white;
    cursor: pointer;
}
.ico-menu-home-estudiantes3:hover svg > g{
    
    fill: rgba(10, 39, 73, 1);
    ;
}
.ico-menu-home-estudiantes3:hover a{
    
    color: rgba(10, 39, 73, 1);
    ;
}
.icono-sub-menu-heder-estu1{
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;
}
.nombre-header-estu1{
    color: white;
}
.sub-menu-header{
    width: 45px;
    height: 45px;
    padding-top: 5px;
    cursor: pointer;
    height: 60px;

}