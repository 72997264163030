.cont-descrip-vista {
  padding: 0px 150px 0 75px;
  margin-top: 40px;
  text-align: center;
}
.cont-descrip-vista > h4 {
  font-size: 21px;
  color: rgba(0, 0, 0, 0.692);
  font-weight: 500;
}
.cont-header-curso {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  margin-bottom: 10px;
}
.cont-info-jornada {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;
  margin: 20px 170px 20px 230px;
}
.cont-info-jornada strong {
  color: #00639a;
  font-size: 20px;
}
.cont-info-jornada p {
  color: #00639a;
  font-size: 20px;
}
.cont-img-opciones {
  height: 250px;
  width: 410px;
  margin: 40px;
  border: 1px solid black;
  border-radius: 20px;
  display: flex;
  flex-direction: column-reverse;
  color: white;
  cursor: pointer;
  overflow: hidden
}

.cont-img-opciones  p {
  font-size: 13px;
}
.cont-img-opciones div{
  background-color: #81818170;
  padding: 5px;
}
.cont-texto-card {
  padding: 15px 20px;
  text-align: center;
}
.cont-texto-card > h2 {
  color: #00639a;
  font-size: 25px;
  font-weight: 900;
}
.cont-titulo {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  height: 300px;
  align-items: center;
  justify-content: center;
}
.cont-opciones {
  margin: -40px 18% 140px 14%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
}
.cont-libro-aula {
  margin: -40px 18% 20px 14%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
}
.cont-libro-aula img {
  width: 100%;
  height: 220%;
}
.cont-libro-aula .img-c {
  width: 185px;
  padding: 20px;
  height: 55%;
}

.cont-opciones-header {
  background-color: rgba(0, 99, 154, 1);
  height: 150px;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 140px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 140px;
  margin-right: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 30px 5px 120px;
}
.cont-opciones-header > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 140px;
  height: 100px;
  margin-top: 20px;
  cursor: pointer;
}

.cont-opciones-header img {
  width: 30px;
  height: 30px;
}

.cont-opciones-header p {
  color: white;
  padding: 0px 10px;
  font-size: 13px;
  text-align: center;
}
.cuadrado-header-curso {
  background-color: rgba(0, 99, 154, 1);
  border-radius: 60px;
  width: 250px;
  height: 280px;
  position: relative;
  z-index: -1000;
  top: 50px;
}

.curso {
  margin: 0px 40px;
  border-radius: 20px;
  box-shadow: 3px 3px 5px rgba(128, 128, 128, 0.651);
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.imagen-opciones {
  width: 100%;
  height: 100%;
}
.img-tarjeta {
  width: 100%;
  height: 250px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.titulo-principal {
  border-radius: 100%;
  background-color: #ffe051;
  height: 370px;
  width: 370px;
  position: relative;
  top: -60px;
  left: -30%;
  z-index: -100;
}
.titulo-principal > p {
  position: relative;
  top: 90px;
  left: 120px;
  font-size: 12px;
  color: white;
}
.titulo-secunda {
  border-radius: 100%;
  background-color: #0090ddd3;
  height: 220px;
  width: 220px;
  margin-left: 190px;
  margin-top: 100px;
}
.titulo-secunda > h2 {
  color: white;
  position: relative;
  top: 90px;
  left: -20px;
  font-size: 40px;
  font-weight: 900;
}
.obalo-header-curso {
  background-color: rgba(0, 99, 154, 1);
  border-radius: 100%;
  width: 230px;
  height: 370px;
  position: relative;
  transform: rotate(-50deg);
  top: -50px;
  left: 120px;
}
.obalo-header-curso h2 {
  transform: rotate(50deg);
  color: white;
  position: absolute;
  font-size: 15px;
  top: 40px;
  left: 90px;
  z-index: 10000000;
}
.obalo-rosado {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 50px;
  left: 230px;
  background-color: #ffaf8dec;
  border-radius: 100%;
}
.obalo-rosado > h2 {
  color: white;
  position: relative;
  top: 80px;
  left: -90px;
  font-size: 40px;
  font-weight: 900;
}

.obalo-rosado1 {
  background-color: #ffaf8dec;
  width: 400px;
  height: 220px;
  position: absolute;
  top: 60px;
  left: 280px;
  border-radius: 100%;
  transform: rotate(30deg);
  z-index: -10000;
  clip-path: ellipse(60% 49% at 0% 51%);
}
.separador-misAulas {
  width: 2px;
  height: 40%;
  margin-top: 10px;
  background-color: #1cb0ffa6;
}

#actividades {
  background-image: url("https://img.freepik.com/free-vector/worker-rating-illustration_1893-2212.jpg?size=626&ext=jpg");
}
#planilla {
  background-image: url("https://thumbs.dreamstime.com/b/qualification-skill-test-employment-vector-illustrator-concept-96887555.jpg");
}
#evaluaciones {
  background-image: url("https://img.freepik.com/free-vector/employer-meeting-job-applicant-pre-employment-assessment-employee-evaluation-assessment-form-report-performance-review-concept-illustration_335657-2058.jpg?size=626&ext=jpg");
}
#libros {
  background-image: url("https://img.freepik.com/free-vector/people-library-flat-vector-illustration_74855-6607.jpg?size=626&ext=jpg");
}
