.textarea-container {
    position: relative;
    width: 300px; /* Ajusta el ancho según sea necesario */
}
.char-count {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 0.75em;
    color: gray;
}