.cont-calendario-home{
    background: #a8cee2;
    border-radius: 10px;
    padding-bottom: 10px;
    margin: 0px 70px 110px 70px;
}
.dias-calendario{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 4px 10px;
    margin: 10px 20px;
    background-color: white;
    
}
.cont-calendario-home1{
    background: #00639A;
    border-radius: 10px;
    padding-bottom: 10px;
    margin: 0px 70px 110px 70px;
}
.cont-calendario-home2{
    background: rgba(255, 153, 60, 1);
    border-radius: 10px;
    padding-bottom: 10px;
    margin: 0px 70px 110px 70px;
}
.cont-calendario-home3{
    background: #56A4CF;
    border-radius: 10px;
    padding-bottom: 10px;
    margin: 0px 70px 110px 70px;
}

.mes-calendario{
    color:#02649b ;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    padding: 5px 5px 5px 20px;
}
.mes-calendario2{
    color:white ;
    font-weight: 900;
    font-size: 15px;
    padding: 5px 5px 5px 20px;
    display: grid;
    grid-template-columns: .1fr 1fr .1fr;
}
.mes-calendario p{
    margin: 0;
    padding: 0;
}
.mes-calendario-contenido{
    display: grid;
    grid-template-columns: .3fr 1fr .3fr;
}
.meses-evaluaciones{
    padding: 0px 4vw;
}
.flecha-calendario{
    font-size: 20px;
    cursor: pointer;
}
.Dia{
    background-color: #56A4CF;
    border-radius: 60px;
    text-align: center;
}