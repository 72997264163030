.form-school {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  width: 100%;
  height: 60%;
  background-color: #00639a;
}

.container-cover-page-image {
  display: flex;
  align-items: flex-end;
  padding: 15px 30px;
  width: 100%;
  height: 100%;
}

.container-cover-page-image input[type="file"] {
  display: none;
}

.container-logo-image {
  width: 110px;
  height: 140px;
  border-radius: 10px;
  background-color: rgb(140, 126, 126);
}

.container-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container-input {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.container-input > input {
  padding: 0px 10px;
  border: #0092e3 2px solid;
  border-radius: 5px;
}

.container-textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #0092e3;
}

.container-textarea > textarea {
  padding: 0px 10px;
  border: #0092e3 2px solid;
  border-radius: 10px;
}

.container-button {
  width: 100%;
  height: 10%;
}

.container-button > button {
  width: 20%;
  height: 90%;
  border: none;
  border-radius: 10px;
  background-color: #0092e3;
  color: #fff;
  cursor: pointer;
}

.z-10 {
  z-index: 10000;
}
