.backdrop-container {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 8000;
}

.modal-container {
  width: 50%;
  height: 50%;
  border-radius: 15px;
  background-color: #fff;
}

.modal-header {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 10%;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
}
