.background-modal {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  width: 60%;
  height: 60%;
  background-color: #fff;
}

.header-container-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 91.333%;
  height: 15%;
}

.btns-action {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btns-action > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #00639a;
}

.btns-action > span {
  font-size: 18px;
}
