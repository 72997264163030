.container-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 91.33333%;
  height: 80%;
  overflow-y: auto;
}

.table-container-modal {
  width: 100%;
  height: 100%;
}

.table-container-modal > thead {
  border-bottom: 1px solid #000;
  width: 100%;
  height: 15%;
}

.table-container-modal > thead > tr {
  width: 100%;
}

.table-container-modal > thead > tr > th {
  text-align: center;
  padding: 10px;
}

.table-container-modal > tbody {
  width: 100%;
  height: 80%;
}

.table-container-modal > tbody > tr {
  border-bottom: 1px solid #000;
  width: 100%;
}

.table-container-modal > tbody > tr > td {
  text-align: center;
  height: 50px;
  padding: 10px;
}

.table-container-modal > tbody > tr > td:nth-child(1) {
  text-align: justify;
}

.table-container-modal > tbody button {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.table-container-modal > tbody > tr > td:nth-child(5) > button {
  background-color: #00639a;
}

.table-container-modal > tbody > tr > td:nth-child(6) > button {
  background-color: rgb(249, 26, 26);
}
