.buscador-codi-estu-coord {
  border: 1px solid #0a2749;
  box-sizing: border-box;
  border-radius: 30px;
  width: 100%;
  height: 41px;
  font-weight: 300;
  font-size: 16px;
  padding-left: 20px;
}
.cont-filtores-cord {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0px 40px 0px;
}
.cont-header-usuario-coor1 {
  height: 300px;
  width: 80%;
}
.cont-header-usuario-coor1 h4 {
  position: relative;
  top: 40px;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: white;
  left: 100px;
  display: inline-block;
}
.cont-header-usuario-coor1 svg {
  position: relative;
  top: -260px;
  left: -110px;
  z-index: -100;
}
.cont-header-usuario-coor2 {
  position: absolute;
}
.cont-header-usuario-coor2 h4 {
  position: absolute;
  top: -60px;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: white;
  left: 100px;
  display: inline-block;
}
.cont-header-usuario-coor2 svg {
  position: absolute;
  top: -300px;
  left: -110px;
  z-index: -100;
}
.filtros-busca-coor {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  height: 300px;
  margin: 90px 40px 0px 0%;
}
.select-filtro-coor-estu select {
  background-color: white;
  border: none;
  border-bottom: 1px solid #707070;
  width: 100%;
}
.select-filtro-coor-estu {
  margin: 20px 100px 0px 0px;
}
.select-filtro-coor-estu h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #00639a;
}
.select-filtro-coor-estu1 select {
  background-color: white;
  border: none;
  border-bottom: 1px solid #707070;
  width: 100%;
}
.select-filtro-coor-estu1 {
  margin: 20px 20px 20px 0px;
}
t .select-filtro-coor-estu1 h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #00639a;
}

#espaciado-filtro-estu-coord {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-right: 240px;
}
#espaciado-filtro-estu-coord1 {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-right: 90px;
}
