.cont-datos-mejor-colegio3 {
  margin: 50px 140px;
}
.cont-datos-mejor-colegio3 h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: rgba(4, 41, 58, 1);
  margin-bottom: 70px;
  text-align: center;
}
.cont-icono-sub-menu-home-estu3 {
  background-color: rgba(10, 39, 73, 1);
  box-sizing: border-box;
  border-radius: 100%;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  padding: 10px 10px;
  text-align: center;
  cursor: pointer;
}
.cont-icono-sub-menu-home-estu3 img {
  width: 40px;
  height: 40px;
}
.cont-info-datos-estu3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 160px;
  margin-bottom: 150px;
}
.cont-info-principla-home3 {
  height: 170vh;
}
.cont-info-tablero-estu3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 50px 20px 20px 20px;
  height: 31.25em;
  width: 95%;
  border-radius: 35px;
  padding: 20px 30px;
}

.container-note-list {
  height: 90%;
  width: 100%;
  overflow-y: scroll;
}
.con-nobre-home-estu3 h4 {
  font-weight: 700;
  font-size: 50px;
  line-height: 75px;
  color: #ffffff;
  text-align: center;
  padding-top: 100px;
}
.cont-sub-menu-ico-estu3 {
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr;
  margin: 50px 15% 0px 20%;
}
.con-titu-table-mejor-estu3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: rgba(86, 164, 207, 1);
  border-radius: 5px;
  color: white;
  padding: 20px 20px;
  text-align: center;
  margin-bottom: 4px;
}
.info-estu3 {
  margin: -90px 170px 40px 170px;
}
.info-estu3 h3 {
  color: #0a2749;
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.info-estu3 p {
  color: #0a2749;
  font-size: 18px;
  font-weight: 400px;
  text-align: left;
  padding: 30px 100px;
}
.info-estu3-colegio {
  margin: 0px 160px 40px 170px;
}
.info-estu3-colegio h3 {
  color: #0a2749;
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.info-estu3-colegio p {
  color: #0a2749;
  font-size: 18px;
  font-weight: 400px;
  text-align: left;
  padding: 30px 0px;
}
.logo-estu3-home {
  margin: 0px auto;
  position: relative;
  top: 40px;
}
.tablero-estu3 {
  margin-bottom: 130px;
  margin-left: 180px;
  margin-right: 180px;
  border-radius: 45px;
  background-color: rgba(225, 225, 225, 1);
  padding-bottom: 10px;
}
.titu-ciclo-estu3-home {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 57px;
  color: #04293a;
  text-align: center;
  padding-top: 60px;
  margin-left: -20px;
}
#circulo-fondo-estu3 {
  position: absolute;
  width: 120vw;
  height: 90vw;
  top: -400px;
  left: -700px;
  background-color: rgba(131, 163, 175, 1);
  border-radius: 100%;
  z-index: -100000;
}
