.btn-cancelar-coord {
  border: 1px solid #28b2ff;
  box-sizing: border-box;
  border-radius: 7px;
  height: 55px;
  text-align: center;
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #28b2ff;
  margin: 0px 20px;
}
.btn-guardar-coord {
  background: #28b2ff;
  border-radius: 7px;
  height: 55px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  padding: 16px 0px;
  margin: 0px 10px;
  text-align: center;
}
.cont-btn-form-coord {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 45%;
  justify-content: flex-end;
}
.cont-buscador-cordi {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  margin: 150px 12% 70px 12%;
}
.cont-buscador-cordi input {
  width: 100%;
  height: 41px;
  border-radius: 20px;
  border: 1px solid #0a2749;
  outline: none;
  padding-left: 20px;
}
.cont-buscador-cordi select {
  width: 50%;
  height: 41px;
  border: none;
  border-bottom: 1px solid #0a2749;
  padding-left: 20px;
  background-color: white;
  margin-left: 130px;
}
.cont-edit-datos-coordi {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 10px 12%;
}
.cont-edit-datos-form-coord {
  padding: 40px 60px;
}
.cont-edit-datos-form-coord h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00639a;
  border-bottom: 2px solid #00639a;
  margin-left: 15px;
  display: inline-block;
  padding-right: 25px;
  margin-bottom: 30px;
}
.datos-docente-coordi {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.3fr 0.3fr;
  border-bottom: 2px solid rgba(0, 99, 154, 0.1);
  padding: 15px 0;
  text-align: center;
}
.datos-docente-coordi svg {
  cursor: pointer;
}
.descri-edit-docente-coordi {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: #0a2749;
  margin: 90px 220px 90px 45%;
}
.div-info-coord {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0px 20px;
}
.edit-user-coordi {
  background: #00639a;
  border-radius: 10px 10px 0px 0px;
  color: white;
  padding: 15px 50px;
  font-weight: 700;
  font-size: 16px;
}
.info-form-coordi {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  grid-gap: 15px;
}
.info-form-coordi1 {
  display: grid;
  background: rgba(0, 99, 154, 0.1);
  border-radius: 10px;
  grid-template-columns: 1fr 0.8fr;
  grid-gap: 15px;
  margin: 40px 40px;
  padding: 30px 15px;
}
.info-form-coordi1 h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #00639a;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 300px;
  margin-left: 20px;
}
.input-form-edit-coor {
  border: 1px solid #00639a;
  box-sizing: border-box;
  border-radius: 10px;
  height: 30px;
  padding-left: 15px;
  background-color: white;
}
.label-coord-form {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #3d3c3c;
}
.tabla-docentes-coordi {
  display: grid;
  grid-template-columns: 1fr;
  margin: 10px 9% 90px 9%;
}
.titulo-tabla-docentes-coordi {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.3fr 0.3fr;
  background: rgba(0, 99, 154, 0.9);
  color: white;
  text-align: center;
  padding: 15px 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}
.titulo-tabla-docentes-coordi div {
  font-weight: 500;
  font-size: 16px;
}
