.libros-temas{
    background: #FFB000;
    border-radius: 2rem;
    border: 0.2rem solid black;
    max-height: 20rem;
}

.aula-virtual{
    background: #FF6D00;
    border-radius: 2rem;
    border: 0.2rem solid black;
    max-height: 20rem;
}

.evaluaciones {
    background: #FF0000;
    border-radius: 2rem;
    border: 0.2rem solid black;
    max-height: 20rem;
}


.agendaVirtual{
    background: #0074FF;
    border-radius: 2rem;
    border: 0.2rem solid black;
    max-height: 20rem;
}

.bg-black{
    background: black;
}

.img-register{
    width: 100%;
    height: 20rem;
}

body{
    font-family: Questrial;
}

.containerMenuResponsive{
    display: none;
}

.MenuResContainer{
    position: absolute;
    z-index: 1000;
    transform: translateX(-50px);
    width: 100%;
    height: 100%;
    animation: 0.5s agregar;
}

@media (max-width:999px) {
    .aula-virtual{
        max-height: 100%;
    }
    .libros-temas{
        max-height: 100%;
    }
    .evaluaciones {
        max-height: 100%;
    }

    .agendaVirtual{
        max-height: 100%;
    }

}

@media (max-width:425px) {
    .containerMenuResponsive{
        display: block;
    }
    .containerMenu{
        display: none;
    }
    .img-register{
        width: 100%;
        height: 13rem;
    }
}

@keyframes agregar {
    100%{
        transform: translateX(-50px);
    }
    0%{
        transform: translateX(1000px);
    }
}