.cont-info-perfil-home{
    display: grid;
    grid-template-columns:  1fr;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}
.cont-info-perfil-home img{
    width: 74px;
    height: 74px;
}
.cont-info-perfil-home p{
    color: white;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    margin-top: 20px;
    padding: 0;
}
.cont-header-estudiantes-home2{
    background-color: rgba(0, 128, 141, 1);
    height: 170vh; 
}
.cont-home-menu-estu1{
    display: grid;
    grid-template-columns: .35fr 1fr;
}
.cont-menu-home{
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 70px;
}
.datos-perfil-home{
    display: grid;
    grid-template-columns: .5fr 1fr;
}
.ico-menu-home-estudiantes1-home{
    border: 3px solid #04293A;
    box-sizing: border-box;
    border-radius: 15px;
    width:240px;
    height: 70px;
    margin-bottom: 30px;
    margin-right: 30px;
    display: grid;
    grid-template-columns: .2fr 1fr;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}
.ico-menu-home-estudiantes1-home:hover{
    transition: background-color 0.2s ;
    background-color:rgba(4, 41, 58, 1) ;
    fill:white;
    cursor: pointer;
    position: relative;
    top:-5px;
    left:5px
}
.ico-menu-home-estudiantes1-home:hover svg > g{
    
    fill:white;
}
.ico-menu-home-estudiantes1-home:hover a{
    
    color:white;
}
.ico-menu-home-estudiantes1-home img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.logo-header-home{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    
}
.logo-header-home img{
    height: 78px;
    width: 125px;
    
}