.btn-acti-biblioteca3{
    border: 1px solid #56A4CF;;
    box-sizing: border-box;
    border-radius: 7px;
    color: #56A4CF; ;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
}
.btn-acti-lectura3{
    background:  #56A4CF;;
    border-radius: 7px;
    color:white;
    padding: 10px 40px;
    font-weight: 500;
    font-size: 13px;
    margin-right: 30px;
    cursor: pointer;
}
.btn-iniciar-acti-estu-3{
    display: inline-block;
    width: 180px;
    height: 52px;
    background: #56A4CF;;
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 15px 40px;
    margin-left: 170px;
    cursor: pointer;
}
.card-actividades-estu3{
    display: grid;
    grid-template-columns: 1fr .7fr;
    background: radial-gradient(91.29% 91.29% at 115.07% -4.07%, #F9F9F9 0%, rgba(196, 196, 196, 0) 99.99%, rgba(86, 164, 207, 0.25) 100%);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 60px;
    max-width: 875px;
}
.card-actividades-estu3 h5{
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    color: #3D3C3C;
}
.card-actividades-estu3 h6{
    font-weight: 300;
    font-size: 15px;
    line-height: 20px; 
    color: #3D3C3C;
}
.card-actividades-estu3 p{
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #3D3C3C;
}
.card-actividades-estu3 img{
   margin: 40px 60px;
}
.card-cont-img-actividades3{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}
.pregunta-click{
    cursor: pointer;
}
.pregunta-click:hover {
    transform: scale(1.02); /* Escala el elemento al 110% del tamaño original al pasar el mouse sobre él */
  }
