.barras-cortas-cont-estu-2 {
  margin-top: -50px;
  background-color: #a0a0a0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.barras-largas-cont-estu-2 {
  margin-top: -100px;
  background: #858585;
  border-top-left-radius: 65px;
  border-top-right-radius: 65px;
}
.btn-salir-home-estu2 {
  background: #00a4ff;
  border-radius: 10px;
  color: white;
  height: 55px;
  width: 210px;
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  margin: 25px 15px;
  padding-top: 10px;
}
.btn-salir-home-estu2:hover {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(98, 199, 255, 0.5) 0%,
      rgba(0, 164, 255, 0) 100%
    );

  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  transition: left 2s;
  left: 2px;
  transition: top 2s;
  top: -2px;
}
.cont-evento-home-estu1 {
  display: grid;
  grid-template-columns: 1fr;
  width: 390px;
}
.cont-evento-home-estu1 > div {
  background: #04293a;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  font-size: 20px;
  padding-top: 15px;
  padding-left: 20px;
  margin: 10px 0px;
}
.cont-evento-home-estu1 > div:hover {
  position: relative;
  transition-property: left;
  transition-duration: 2s;
  left: 15px;
  cursor: pointer;
}
.cont-info-principla-home2 {
  background: grey;
  height: 170vh;
}
.cont-info-datos-estu2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: -130px;
  margin-bottom: 150px;
}
.cont-info-tablero-estu2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e1e1e1;
  margin: 50px 20px 20px 20px;
  border-radius: 35px;
  padding: 20px 30px;
  height: 500px;
}
.list-note-container {
  height: 80%;
  width: 100%;
  overflow-y: scroll;
}
.cont-notas-tablero-estu2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 6%;
  height: 80px;
  border-bottom: 1px solid #04293a;
}
.cont-notas-tablero-estu2 h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #04293a;
  padding: 10px 0px 0px 30px;
}
.con-pasar-fotos-estu2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 30px 230px 10px 100px;
  cursor: pointer;
}
.con-pasar-fotos-estu2 > div {
  background: #c4c4c4;
  border-radius: 100%;
  width: 15px;
  height: 15px;
}
.cont-datos-mejor-colegio2 {
  width: 70%;
}
.cont-datos-mejor-colegio2 h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
.cont-icono-sub-menu-home-estu2 {
  border: 4px dashed #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  padding: 10px 10px;
  text-align: center;
}
.cont-icono-sub-menu-home-estu2:hover {
  border: none;
  box-sizing: border-box;
  transition: background-color 2s;
  background-color: rgba(4, 41, 58, 1);
  transition: color 0.7s;
  color: white;
  cursor: pointer;
}
.cont-icono-sub-menu-home-estu2:hover > svg > g {
  transition: fill 0.7s;
  fill: white;
}
.con-titu-table-mejor-estu2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #00808d;
  border-radius: 5px;
  color: white;
  padding: 20px 20px;
  text-align: center;
  margin-bottom: 4px;
}
.cont-tdatos-table-estu2 {
  background: rgba(221, 221, 221);
  box-sizing: border-box;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 60px;
  margin: 4px 0px;
  text-align: center;
}
.cont-tdatos-table-estu2 > div {
  padding-top: 15px;
  text-align: center;
}
.info-estu2 {
  margin: 90px 170px 40px -40px;
}
.info-estu2 h3 {
  color: #0a2749;
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.info-estu2 p {
  color: #0a2749;
  font-size: 18px;
  font-weight: 400px;
  text-align: left;
  padding: 30px 0px;
}
.info-estu2-colegio {
  margin: 0px 160px 40px -120px;
}
.info-estu2-colegio h3 {
  color: #0a2749;
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.info-estu2-colegio p {
  color: #0a2749;
  font-size: 18px;
  font-weight: 400px;
  text-align: left;
  padding: 30px 0px;
}
.mejores-colegio-home-estu2 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  background-color: #4f4f4f;
  width: 98.7vw;
  height: 100%;
  margin-left: -500px;
  position: absolute;
  z-index: -1;
}
.mejores-colegio-home-estu2 > div {
  margin-left: 10px;
}
.tablero-curso-circulo-estu2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.tablero-curso-circulo-estu2 > div {
  border-radius: 100%;
  background-color: #fcff68;
  margin-top: 20px;
  margin-left: 140px;
  height: 50px;
  width: 50px;
}
.tablero-estu2 {
  margin-bottom: 130px;
  margin-left: -90px;
  margin-right: 190px;
  border-radius: 45px;
  background-color: #04293b;
  padding-bottom: 10px;
}
.best-of-school {
  width: 100%;
  position: relative;
}

#deco-engranaje1 {
  position: absolute;
  top: 200vh;
  left: 60px;
  z-index: -10000;
  animation: rotarEngranje 25s infinite;
}
#deco-engranaje2 {
  position: absolute;
  top: 250vh;
  left: 1080px;
  z-index: -10000;
  animation: rotarEngranjeAtras 25s infinite;
}

#deco-engranaje3 {
  animation: rotarEngranjeAtras 25s infinite;
}
#deco-engranaje2 img {
  height: 194px;
  width: 177px;
}
#fondo-estu2 {
  background-image: url("../img/fondo-estu2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes rotarEngranje {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(359deg);
  }
}
@keyframes rotarEngranjeAtras {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-359deg);
  }
}
