.click{
    cursor: pointer;
}

.option {
    padding: 10px;
    transition: transform 0.3s ease; /* Agrega una transición al cambio de tamaño */
  }
  .option:hover {
    transform: scale(1.1); /* Aumenta el tamaño al pasar el mouse sobre el elemento */
  }
.optionsingle {
    transition: transform 0.3s ease; /* Agrega una transición al cambio de tamaño */
  }
  
  .optionsingle:hover {
    transform: scale(1.1); /* Aumenta el tamaño al pasar el mouse sobre el elemento */
  }
  
  .portada{
    max-width: 150px;
    max-height: 230px;
  }